import { createMemo, createSignal, splitProps } from "solid-js";
import { createQuestionInputs } from "../../../../courses/forms";
import { ShowMaterialQuestionView } from "src/modules/courses/models";
import { putQuestion, postQuestion } from "../../../apis";

export function ShowMaterialQuestion(props: ShowMaterialQuestionView) {
  const [local, others] = splitProps(props, ["$", "events", "options", "elements"]);
  if (local.elements?.data === undefined || local.elements?.data === null) {
    throw new Error("ShowMaterialQuestion: data cannot be undefined or null!");
  }
  const taskID = local.elements.data?.id;
  const title = local.elements.data.content?.details?.info;
  const questionObj = local.elements.data.content.content?.[0];
  const [questionAns, setQuestionAns] = createSignal(questionObj.answer);
  const FormInputs = createQuestionInputs();
  FormInputs.Answer.control.setValue(questionObj.answer);
  const char_limit = 4000;
  const $written_chars = createMemo(() => {
    const user_chars = FormInputs.Answer.control.value;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  });

  const onSubmit = async () => {
    const answerValue = FormInputs.Answer.control.value;
    const obj = { ...questionObj };
    obj["answer"] = answerValue;
    const payload = {
      content: obj,
    };
    if (questionAns() !== null) {
      await putQuestion(taskID, "", payload)
        .then(() => {
          local.$.actions.pushToast({
            type: "success",
            message: "Answer updated successfully",
          });
          local.events?.onSuccess?.();
        })
        .catch(() => {
          local.$.actions.pushToast({
            type: "error",
            message: "Answer update failed",
          });
          local.events?.onFailed?.();
        });
    } else {
      await postQuestion(taskID, payload)
        .then(() => {
          local.$.actions.pushToast({
            type: "success",
            message: "Answer created successfully",
          });
          local.events?.onSuccess?.();
        })
        .catch((e) => {
          local.$.actions.pushToast({
            type: "error",
            message: "Answer update failed",
          });
          local.events?.onFailed?.();
        });
    }
  };
  return (
    <div class="flex flex-col pt-6 w-99% max-w-99%">
      <h3 class="py-2 text-20px font-normal ">{questionObj.question}</h3>
      <div class="relative w-full h-fit">
        <FormInputs.Answer.TextArea
          class="resize bg-inherit text-0.8rem min-h-150px w-full max-w-full min-w-full px-23px py-18px border-2 border-#112836"
          cols="100"
          maxlength={char_limit}
        />
        <div class="absolute right-25px bottom-20px text-gray-300 text-19px">{$written_chars()}</div>
      </div>
      <div class="flex w-full justify-end items-center mb-3 ">
        {!local.options?.startShow && (
          <FormInputs.Actions.Button
            statusValid="Submit Answer"
            statusInvalid="Submit Answer"
            class="form-btn !w-270px px-4 py-2 text-white capitalize  text-17px cursor-pointer whitespace-nowrap"
            onclick={onSubmit}
          />
        )}
      </div>
    </div>
  );
}
